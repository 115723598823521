import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Header from "./header";
import image from "../img/check-mark.gif";

class finalPage extends Component {
  constructor(props) {
    super(props);
    if (!this.props.cookies.get("userAuth")) {
      this.props.history.push("/");
    }
  }
  render() {
    if (!this.props.cookies.get("userAuth")) {
      this.props.logOut();
      return <Redirect to="/" />;
    }
    // if (!this.props.auth.isLogin) {
    //   return <Redirect to="/" />;
    // }
    return (
      <div>
        <Header />
        <section className="main-body">
          <div className="container">
            <div className="screen-ten">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <h4 className="text-center mb-3 top-title">
                    Thanks {this.props.auth.firstName} for Setting and
                    Appointment with us !
                  </h4>
                  <div className="box box-primary">
                    <form className="">
                      <div className="box-body">
                        <div className="text-center">
                          <img
                            src={image}
                            alt={"done"}
                            style={{ height: "100px" }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => {
      dispatch({
        type: "USER_LOGOUT"
      });
    },
    setCookieState: data => {
      dispatch({
        type: "setFromCookie",
        data: data
      });
    }
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(finalPage);
