import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { ADD_AREA_INFO } from "../store/actions/infoAction";
import Header from "./header";
import Footer from "./footer";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class areaSelection extends Component {
  constructor(props) {
    super(props);
    this.allItems = [
      "check-all",
      "all-area",
      "grade-area",
      "work-shops",
      "storage-sheds",
      "gareges",
      "porches",
      "decks",
      "patios",
      "sporting-areas",
      "pools",
      "wack-wats",
      "pool-houses",
      "cabanas",
      "all-other"
    ];
    this.state = {
      items: props.items.areas ? props.items.areas : this.allItems
    };
  }

  componentDidMount() {
    const oldData = this.state.items;
    oldData.forEach(element => {
      if (document.getElementById(element))
        document.getElementById(element).checked = true;
    });
  }

  onBack_handler = e => {
    this.props.addAreaInfo(this.state.items);
    this.props.history.push("/customer-information");
  };

  onHelp_handler = e => {
    confirmAlert({
      title: "Areas to be Measured",
      message: (
        <div
        // onClick={() => onClose()}
        >
          <p>
            Click the check box(es) to include all rooms and areas matching the
            selected type in the measurement. <br />
            All measured areas will be evaluated for type and other
            characteristics and included on the measuring report. Examples:
          </p>
          <ul style={{ listStyleType: "square" }}>
            <li>Main / Separate building</li>
            <li>Finished / Not Finished</li>
            <li>Living / Non-Living</li>
            <li>Above Grade / Below Grade</li>
          </ul>
        </div>
      ),
      buttons: [
        {
          label: "Got it!"
        }
      ],
      closeOnEscape: true
    });
  };

  onSelect_handler = () => {
    this.props.addAreaInfo(this.state.items);
    this.props.history.push("/appointment-time");
  };
  onCancel_handler = () => {
    this.props.clearInfo();
    this.props.logOut();
    this.props.history.push("/");
  };

  onCheckall_handler = e => {
    // const name = e.target.id;
    if (e.target.checked) {
      this.setState({
        items: this.allItems,
        checkAll: true
      });
      this.allItems.forEach(element => {
        if (document.getElementById(element))
          document.getElementById(element).checked = true;
      });
    } else {
      this.allItems.forEach(element => {
        if (document.getElementById(element))
          document.getElementById(element).checked = false;
      });
      this.setState({
        items: [],
        checkAll: false
      });
    }
  };

  onChange_handler = e => {
    const name = e.target.id;
    if (e.target.checked) {
      this.setState(state => ({
        items: [...state.items, name]
      }));
    } else {
      const newState = this.state.items.filter(item => item !== name);
      this.setState({
        items: newState
      });
      if (document.getElementById("check-all"))
        document.getElementById("check-all").checked = false;
    }
  };
  render() {
    if (!this.props.auth.isLogin) {
      return <Redirect to="/" />;
    }
    if (this.props.auth.accountType === "Retailer") {
      return <Redirect to="/customer-information" />;
    }
    if (!this.props.items.customerInfo) {
      return <Redirect to="/customer-information" />;
    }
    return (
      <div>
        <Header />
        <section className="main-body">
          <div className="container">
            <div className="screen-eleven">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="box box-primary">
                    <form className="">
                      <div className="box-header with-border text-center">
                        <h3 className="box-title">Areas to be Measured</h3>
                      </div>
                      <div className="box-body">
                        <h4 className="box-subtitle">
                          Real Estate Measure{" "}
                          <span className="box-text">
                            (Please check all that apply)
                          </span>
                        </h4>
                        <div className="mt-3">
                          <ul className="list-unstyledt">
                            <li>
                              <input
                                className="magic-checkbox"
                                type="checkbox"
                                name="layout"
                                id="check-all"
                                onChange={this.onCheckall_handler}
                                // checked="checked"
                              />
                              <label htmlFor="check-all">Check All</label>
                            </li>
                            <li>
                              <input
                                className="magic-checkbox"
                                type="checkbox"
                                name="layout"
                                id="all-area"
                                onChange={this.onChange_handler}
                              />
                              <label htmlFor="all-area">
                                All Attached Interior Areas, Main Building
                              </label>
                            </li>
                            <li>
                              <input
                                className="magic-checkbox"
                                type="checkbox"
                                name="layout"
                                id="grade-area"
                                onChange={this.onChange_handler}
                                // checked="checked"
                              />
                              <label htmlFor="grade-area">
                                All below Grade Areas, Main Building
                              </label>
                            </li>
                            <li>
                              <input
                                className="magic-checkbox"
                                type="checkbox"
                                name="layout"
                                id="gareges"
                                onChange={this.onChange_handler}
                              />
                              <label htmlFor="gareges">
                                Garages / Carports ( Attached or Unattached )
                              </label>
                            </li>
                          </ul>
                          <div className="row mt-3">
                            <div className="col-sm-6 b-right">
                              <h4 className="box-subtitle">Exterior</h4>
                              <ul className="ml-4 list-unstyled">
                                <li>
                                  <input
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="layout"
                                    id="porches"
                                    onChange={this.onChange_handler}
                                  />
                                  <label htmlFor="porches">Porches</label>
                                </li>
                                <li>
                                  <input
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="layout"
                                    id="decks"
                                    onChange={this.onChange_handler}
                                  />
                                  <label htmlFor="decks">Decks</label>
                                </li>
                                <li>
                                  <input
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="layout"
                                    id="patios"
                                    onChange={this.onChange_handler}
                                  />
                                  <label htmlFor="patios">Patios</label>
                                </li>
                                <li>
                                  <input
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="layout"
                                    id="sporting-areas"
                                    onChange={this.onChange_handler}
                                  />
                                  <label htmlFor="sporting-areas">
                                    Sporting Areas
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="layout"
                                    id="pools"
                                    onChange={this.onChange_handler}
                                  />
                                  <label htmlFor="pools">Pools</label>
                                </li>
                                <li>
                                  <input
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="layout"
                                    id="wack-wats"
                                    onChange={this.onChange_handler}
                                  />
                                  <label htmlFor="wack-wats">WalkWays</label>
                                </li>
                              </ul>
                            </div>
                            <div className="col-sm-6">
                              <h4 className="box-subtitle">Other Buildings</h4>
                              <ul className="ml-4 list-unstyled">
                                <li>
                                  <input
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="layout"
                                    id="pool-houses"
                                    onChange={this.onChange_handler}
                                  />
                                  <label htmlFor="pool-houses">
                                    Pool Houses
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="layout"
                                    id="cabanas"
                                    onChange={this.onChange_handler}
                                  />
                                  <label htmlFor="cabanas">Gazebos</label>
                                </li>
                                <li>
                                  <input
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="layout"
                                    id="work-shops"
                                    onChange={this.onChange_handler}
                                  />
                                  <label htmlFor="work-shops">Work Shops</label>
                                </li>
                                <li>
                                  <input
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="layout"
                                    id="storage-sheds"
                                    onChange={this.onChange_handler}
                                  />
                                  <label htmlFor="storage-sheds">
                                    Storage Sheds
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="magic-checkbox"
                                    type="checkbox"
                                    name="layout"
                                    id="all-other"
                                    onChange={this.onChange_handler}
                                  />
                                  <label htmlFor="all-other">
                                    All Other Site Buildings
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="box-footer with-border">
                        <div className="">
                          <ul className="custom-list">
                            {/* back */}
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Back"
                                onClick={this.onBack_handler}
                              />
                            </li>
                            <div>
                              <li className="list-inline-item">
                                <input
                                  type="button"
                                  className="btn btn-primary"
                                  value="Help"
                                  onClick={this.onHelp_handler}
                                />
                              </li>

                              <li className="list-inline-item">
                                <input
                                  type="button"
                                  className="btn btn-primary"
                                  value="Cancel"
                                  onClick={this.onCancel_handler}
                                  id="prev-s3"
                                />
                              </li>
                            </div>

                            {/* //next */}
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Next"
                                onClick={this.onSelect_handler}
                                id="next-s12"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div style={{ position: "absolute", width: "100%", bottom: 0 }}>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addAreaInfo: data => {
      dispatch({
        type: ADD_AREA_INFO,
        data: data
      });
    },
    logOut: () => {
      dispatch({
        type: "USER_LOGOUT"
      });
    },
    clearInfo: () => {
      dispatch({
        type: "CLEAR_INFO"
      });
    },
    setCookieState: data => {
      dispatch({
        type: "setFromCookie",
        data: data
      });
    }
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    items: state.items
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(areaSelection);
