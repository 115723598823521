import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import momenttz from "moment-timezone";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { clearTimer } from "../store/reducers/authReducer";

import { ADD_REMINDER } from "../store/actions/infoAction";
import Header from "../components/header";

class appointmentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reminder: props.items.reminder ? props.items.reminder : [],
      loading: false,
      // error: false
    };
  }

  onCancel_handler = () => {
    // console.log(this.props.cookies.get("userAuth").accountType)
    this.props.clearInfo();
    this.props.logOut();
    this.props.history.push("/");
  };

  onBack_handler = () => {
    this.props.history.push("/instruction");
  };

  onNext_handler = () => {
    this.props.addReminder(this.state.reminder);
    this.props.history.push("/confirmation");
  };

  onChange_handler = e => {
    const newReminder = e.target.id === "4" ? "email" : "text";
    if (e.target.checked) {
      this.setState(state => ({
        reminder: [...state.reminder, newReminder]
      }));
    } else {
      const newState = this.state.reminder.filter(item => item !== newReminder);
      this.setState({
        reminder: newState
      });
    }
  };

  onFinish_handler = pathToRedirect => {
    const customerData = this.props.items.customerInfo;
    if(!this.state.loading){
      this.setState({
        loading: true,
        // error: false
      });
    }
    const propertyName = customerData.propertyName;
    console.log("projectId:"+localStorage.getItem("projectId"));
    delete customerData.propertyName;
    delete customerData.blockState;
    // Send customer email overrided to TRUE
    const customerEmailReminder = this.props.items.reminder.includes("email");
    // const customerEmailReminder = this.props.items.reminder.includes("email");
    //const appointmentNotSet = this.props.items.appointment === "Not Set";
    const appointmentNote = this.props.items.instruction;
    const month = new Date(this.props.items.appointmentDate).getMonth();
    const year = new Date(this.props.items.appointmentDate).getFullYear();
    const day = new Date(this.props.items.appointmentDate).getDate();
    const timeOfDay = this.props.items.appointment;
    // const clientTime = new Date().toGMTString();
    var clientTime = new Date().toUTCString();
    var myTimezone = "America/Toronto";
    var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss A z";
    var clientTime = moment(clientTime).tz(moment.tz.guess()).format(myDatetimeFormat);
    const rooms = this.props.items.rooms;
    const areas = this.props.items.areas;
    axios.defaults.timeout = 120000;
    //axios.defaults.timeout = 3000;
    axios
      .post( "https://www.floorsoft.com/FloorWizard/fwapi/measuremaker/projectCreateMeasureMaker",
        {
          sessionToken: this.props.auth.sessionToken,
          clientTime: clientTime,
          customerData: {
            propertyName: propertyName,
            primaryPhone: customerData.primaryPhone,
            email: customerData.email,
            firstName: customerData.firstName,
            lastName: customerData.lastName,
            city: customerData.city,
            mobilePhone: customerData.mobileNumber,
            streetAddress1: customerData.street1,
            streetAddress2: customerData.street2,
            zip: customerData.zip,
            state: customerData.state,
            country: customerData.country,
            codeGate: this.props.items.gateCode,
            codeLockBox: this.props.items.lockBoxCode 
          },
          measureAppointmentSetData: {
            projectId: this.props.items.appointment === "Not Set" ? null : (localStorage.getItem("projectId") ? localStorage.getItem("projectId") : null),
            customerEmailReminder: false,
            appointmentNote: appointmentNote,
            appointmentInstruction: null,
            appointmentType: "Measure",
            appointmentSet: false,
            month: this.props.items.appointment === "Not Set" ? null : month,
            year: this.props.items.appointment === "Not Set" ? null : year,
            day: this.props.items.appointment === "Not Set" ? null : day,
            timeOfDay: this.props.items.appointment === "Not Set" ? null : timeOfDay
          },
          measureAreas:
            this.props.items.accountType === "Retailer"
              ? null
              : {
                  allAttachedInteriorAreasMainBuilding: areas.includes(
                    "all-area"
                  ),
                  allBelowGradeAreasMainBuilding: areas.includes("grade-area"),
                  workShops: areas.includes("work-shops"),
                  storageSheds: areas.includes("storage-sheds"),
                  garagesCarports: areas.includes("gareges"),
                  porches: areas.includes("porches"),
                  decks: areas.includes("decks"),
                  patios: areas.includes("patios"),
                  sportingAreas: areas.includes("sporting-areas"),
                  pools: areas.includes("pools"),
                  walkWays: areas.includes("wack-wats"),
                  poolHouses: areas.includes("pool-houses"),
                  gazebos: areas.includes("cabanas"),
                  allOtherSiteBuildings: areas.includes("all-other")
                },
          roomList: this.props.items.accountType === "Retailer" ? rooms : null,
          projectId: localStorage.getItem("projectId")
            ? localStorage.getItem("projectId")
            : null,
          refigureId: null,
          measureAppointment: null,
          messageList: null
        }
      )
      .then(res => {
        // console.log(res.data);
        // return;
         // this.props.addReminder(this.state.reminder);
         //    this.props.history.push("/confirmation");
        
        /*if (!res.data.measureAppointmentSetData.appointmentSet) {
          localStorage.setItem("projectId", res.data.projectId);
          this.props.history.push("/calendar");
        } 
        else {
          localStorage.removeItem("projectId");
          this.props.clearInfo();
          if (pathToRedirect === "logout") {
            this.props.logOut();
            this.props.history.push("/");
          }
          else if(pathToRedirect === "/confirmation"){
            localStorage.removeItem("projectId");
            this.props.addReminder(this.state.reminder);
            this.props.history.push("/confirmation");
          } 
          else {
            this.props.history.push(pathToRedirect);
          }
        }*/
        localStorage      .removeItem ("projectId"        );
        this.props        .clearInfo  (                   );
        this.props        .addReminder(this.state.reminder);
        this.props.history.push       ("/confirmation"    );
      })
      .catch(err => {
        // this.setState({
        //   loading: false,
        //   error: err
        // });
        /*confirmAlert({
          title: "Error",
          message:
            "Sorry, there was an error setting the Appointment. Please try again. If this problem persists, please contact FloorWizard support at 706-226-0960. ",
          buttons: [
            {
              label: "Retry",
              onClick: () => this.onFinish_handler('/calendar')
            },
            {
              label: "Logout",
              onClick: () => {
                document.cookie = "userAuth =; Max-Age=-99999999;";
                clearTimer();
                this.props.history.push("/");
              }
            }
          ],
          closeOnEscape: true
        });*/
        // console.log(err);
        confirmAlert({
          title: "Error",
          message:
            "We have received the customer information, but an error has prevented us from setting the measuring appointment at this time." +
            "It is not necessary to resubmit any of this information." + 
            "Please contact ProMeasuring to confirm and/or re-schedule the appointment." +
            "Phone: (813) 526-5241  Email: brian@promeasuring.com",
          buttons: [
            {
              label: "Finish",
              onClick: () => {
                //document.cookie = "userAuth =; Max-Age=-99999999;";
                //clearTimer();
                //this.props.history.push("/");
                clearTimer();
                this.props.clearInfo();
                this.props.logOut();
                this.props.history.push("/");
              }
            },
            {
              label: "Set Another Appointment",
              onClick: () => {
                //document.cookie = "userAuth =; Max-Age=-99999999;";
                //clearTimer();
                //this.props.history.push("/");
                console.log("Schedule another Apmt");
                clearTimer();
                this.props.clearInfo();
                this.props.history.push("/customer-information");
              }
            }
          ],
          closeOnEscape: true
        });
      });
  };

  componentDidMount() {
    if (this.state.reminder !== null) {
      const oldData = this.state.reminder;
      oldData.forEach(element => {
        element = element === "email" ? "4" : "5";
        if (document.getElementById(element))
          document.getElementById(element).checked = true;
      });
    }
  }

  render() {
    if (!this.props.auth.isLogin) {
      return <Redirect to="/" />;
    }
    if (this.props.auth.accountType === "Realtor" && !this.props.items.areas) {
      return <Redirect to="/area" />;
    }
    if (this.props.auth.accountType === "Retailer" && this.props.items.roomInformation.length === 0) {
      return <Redirect to="/products" />;
    }
    if (this.props.items.appointment === "Not Set") {
      console.log("Appointment Not Set");
      //return <Redirect to="/appointment-time" />;
    }
    else {
      if (!this.props.items.appointment) {
        return <Redirect to="/appointment-time" />;
      }
      if (!this.props.items.appointmentDate) {
        return <Redirect to="/calendar" />;
      }
    }

    return (
      <div>
        <Header />
        <section className="main-body">
          <div className="container">
            <div className="screen-nine">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <h4 className="text-center mb-3 top-title">
                    { this.props.items.appointment === "Not Set" ? "Please confirm the information below:"
                                                                 : "Please confirm the appointment information below:" }
                  </h4>
                  <div className="box box-primary">
                    <form className="">
                      <div className="box-header with-border text-center">
                        <h3 className="box-title">{ this.props.items.appointment === "Not Set" ? "Summary" 
                                                                                               : "Appointment Summary" }
                        </h3>
                      </div>
                      <div className="box-body">
                        <table className="table table-bordered">
                          <tbody>
                            {/* <!--tr>
                      <td><strong>Measure Fee:</strong></td>
                      <td>$35.00</td>
                    </tr--> */}
                            <tr>
                              <td>
                                <strong>Appointment Date:</strong>
                              </td>
                              <td>{ this.props.items.appointment === "Not Set" ? "Measurer to Set" : this.props.items.appointmentDate }</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Time Frame:</strong>
                              </td>
                              <td>{ this.props.items.appointment === "Not Set" ? "Measurer to Set" : this.props.items.appointment }</td>
                            </tr>
                           {/*  <tr>
                              <td colSpan="2">
                                <ul className="list-unstyled">
                                  <li>
                                    <input
                                      className="magic-checkbox"
                                      type="checkbox"
                                      name="layout"
                                      id="4"
                                      onChange={this.onChange_handler}
                                    />
                                    <label htmlFor="4">
                                      Email you a Reminder?
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      className="magic-checkbox"
                                      type="checkbox"
                                      name="layout"
                                      id="5"
                                      onChange={this.onChange_handler}
                                    />
                                    <label htmlFor="5">
                                      Text you a Reminder?
                                    </label>
                                  </li>
                                </ul>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                      <div className="box-footer with-border">
                        <div className="">
                          <ul className="custom-list">
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Back"
                                onClick={this.onBack_handler}
                                id="next-s11"
                              />
                            </li>
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Cancel"
                                onClick={this.onCancel_handler}
                                id="next-s12"
                              />
                            </li>
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value={
                                  this.state.loading === true ? "Submitting..." : this.props.items.appointment === "Not Set" ? "Continue" 
                                                                                                                             : "Schedule Appointment"
                                }
                                onClick={() => this.onFinish_handler('/confirmation')}
                                id="next-s10"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    items: state.items
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addReminder: data => {
      dispatch({
        type: ADD_REMINDER,
        data: data
      });
    },
    logOut: () => {
      dispatch({
        type: "USER_LOGOUT"
      });
    },
    clearInfo: () => {
      dispatch({
        type: "CLEAR_INFO"
      });
    },
    setCookieState: data => {
      dispatch({
        type: "setFromCookie",
        data: data
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(appointmentSummary);
