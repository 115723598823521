import React from "react";
import { Redirect, Route, BrowserRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";

//pages
import Login from "./components/login";
import CustomerInfo from "./components/customerInfo";
import ProductList from "./components/productList";
import RoomInfo from "./components/roomInfo";
import AreaSelection from "./components/areaSelection";
import MeasuringList from "./components/measuringList";
import Appointment from "./components/appointment";
import Calendar from "./components/calendar";
import Instruction from "./components/instructions";
import AppointmentSummary from "./components/appointmentSummary";
import ConfirmationPage from "./components/confirmationPage";
import FinalPage from "./components/finalPage";
import { USER_LOGOUT, USER_LOGIN } from "./store/actions/auth";

//css
import "./components/css/bootstrap.min.css";
import "./components/css/style.css";
import "./components/css/bootstrap-select.min.css";
import "./components/css/magic-check.min.css";
import "./components/aicon/style.css";
// import "./components/css/jquery-pseudo-ripple.css";
import "./components/css/gfont.css";

import { setTimer, clearTimer } from "./store/reducers/authReducer";
import "./App.css";

const App = props => {
  const auth = JSON.parse(localStorage.getItem("userAuth"));
  if (auth) {
    props.login(
      auth.firstName,
      auth.accountType,
      auth.accountName,
      auth.accountId,
      auth.sessionToken,
      auth.sessionTime
    );
    const data = JSON.parse(localStorage.getItem("state" + auth.accountId));
    if (data) {
      props.setCookieState(data);
    }
    setTimer();
  } else {
    clearTimer();
  }
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route path={"/"} exact component={Login} />
          <Route
            path={"/customer-information"}
            exact
            component={CustomerInfo}
          />
          <Route path={"/products"} exact component={ProductList} />
          <Route path={"/room-information"} exact component={RoomInfo} />
          <Route path={"/area"} exact component={AreaSelection} />
          <Route path={"/measurment-list"} exact component={MeasuringList} />
          <Route path={"/appointment-time"} exact component={Appointment} />
          <Route path={"/calendar"} exact component={Calendar} />
          <Route path={"/instruction"} exact component={Instruction} />
          <Route path={"/summary"} exact component={AppointmentSummary} />
          <Route path={"/confirmation"} exact component={ConfirmationPage} />
          <Route path={"/done"} exact component={FinalPage} />
          <Route render={() => <h1>404 Error (Page not found)</h1>} />
          <Redirect from='/' to='/' />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => {
      dispatch({
        type: USER_LOGOUT
      });
    },
    setCookieState: data => {
      dispatch({
        type: "setFromCookie",
        data: data
      });
    },
    login: (
      firstName,
      accountType,
      accountName,
      accountId,
      sessionToken,
      sessionTime
    ) => {
      dispatch({
        type: USER_LOGIN,
        firstName: firstName,
        accountType: accountType,
        accountName: accountName,
        accountId: accountId,
        sessionToken: sessionToken,
        sessionTime: sessionTime
      });
    }
  };
};

// export default withCookies(App);
export default withCookies(connect(null, mapDispatchToProps)(App));
