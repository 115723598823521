import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ADD_APPOINTMENT } from "../store/actions/infoAction";

import Header from "./header";

class appointment extends Component {
  constructor(props) {
    super(props);
    if (this.props.items.appointment) {
      this.state = {
        appointmentTime: this.props.items.appointment
      };
    } else {
      this.state = {
        appointmentTime: null
      };
    }
  }

  onNext_handler = () => {
    if (this.state.appointmentTime === null) return;
    this.props.addAppointment(this.state.appointmentTime);
    this.props.history.push("/calendar");
  };

  onCancel_handler = () => {
    this.props.clearInfo();
    this.props.logOut();
    this.props.history.push("/");
  };

  componentDidMount() {
    const oldData = this.props.items;
    if (oldData.appointment) {
      if (oldData.appointment === "Morning")
        document.getElementById("1").checked = true;
      else if (oldData.appointment === "Afternoon")
        document.getElementById("2").checked = true;
      else document.getElementById("3").checked = true;
    }
  }

  onChange_handler = e => {
    const index = e.target.id;
    if (index === "1") {
      this.setState({
        appointmentTime: "Morning"
      });
    } else if (index === "2") {
      this.setState({
        appointmentTime: "Afternoon"
      });
    } else {
      this.setState({
        appointmentTime: "AM or PM"
      });
    }
  };

  onHelp_handler = () => {
    confirmAlert({
      title: "Appointment Time Frames",
      message: (
        <div
        // onClick={() => onClose()}
        >
          <p>
            Click the radio button that best matches your desired measuring
            appointment time
          </p>
          <p>AM 8:00 AM to 11:59 AM</p>
          <p>PM 12:00 PM to 5:00 PM</p>
          <p>AM / PM 8:00 AM to 5:00 PM</p>
          <p>
            The measuring technician will contact you within 24 hours to set a
            more specific appointment time within your desired time frame.
          </p>
        </div>
      ),
      buttons: [
        {
          label: "Okay"
        }
      ],
      closeOnEscape: true
    });
    // console.log(err);
  };

  onBack_handler = () => {
    // console.log(this.props.cookies.get("userAuth").accountType)
    if (this.props.auth.accountType !== "Realtor")
      this.props.history.push("/measurment-list");
    else this.props.history.push("/area");
  };

  render() {
    if (!this.props.auth.isLogin) {
      return <Redirect to="/" />;
    }
    if (this.props.auth.accountType === "Realtor" && !this.props.items.areas) {
      return <Redirect to="/area" />;
    }
    if (
      this.props.auth.accountType === "Retailer" &&
      this.props.items.roomInformation.length === 0
    )
      return <Redirect to="/products" />;

    return (
      <div>
        <Header />
        <section className="main-body">
          <div className="container">
            <div className="screen-five">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="box box-primary">
                    <form className="">
                      <div className="box-header with-border text-center">
                        <h3 className="box-title">
                          Select Time Frame for Your Appointment
                        </h3>
                      </div>
                      <div className="box-body">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <ul className="list-unstyled">
                                  <li>
                                    <input
                                      className="magic-radio"
                                      type="radio"
                                      name="layout"
                                      id="1"
                                      onChange={this.onChange_handler}
                                    />
                                    <label htmlFor="1">Morning</label>
                                  </li>
                                  <li>
                                    <input
                                      className="magic-radio"
                                      type="radio"
                                      name="layout"
                                      id="2"
                                      onChange={this.onChange_handler}
                                    />
                                    <label htmlFor="2">Afternoon</label>
                                  </li>
                                  <li>
                                    <input
                                      className="magic-radio"
                                      type="radio"
                                      name="layout"
                                      id="3"
                                      onChange={this.onChange_handler}
                                    />
                                    <label htmlFor="3">AM or PM</label>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="box-footer with-border">
                        <div className="">
                          <ul className="custom-list">
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Back"
                                onClick={this.onBack_handler}
                                id="next-s5"
                              />
                            </li>

                            <div>
                              <li className="list-inline-item">
                                <input
                                  type="button"
                                  className="btn btn-primary"
                                  value="Help"
                                  onClick={this.onHelp_handler}
                                  id="next-s7"
                                />
                              </li>
                              <li className="list-inline-item">
                                <input
                                  type="button"
                                  className="btn btn-primary"
                                  value="Cancel"
                                  onClick={this.onCancel_handler}
                                  id="prev-s4"
                                />
                              </li>
                            </div>

                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Next"
                                onClick={this.onNext_handler}
                                id="next-s6"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => {
      dispatch({
        type: "USER_LOGOUT"
      });
    },
    clearInfo: () => {
      dispatch({
        type: "CLEAR_INFO"
      });
    },
    addAppointment: data => {
      dispatch({
        type: ADD_APPOINTMENT,
        data: data
      });
    },
    setCookieState: data => {
      dispatch({
        type: "setFromCookie",
        data: data
      });
    }
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    items: state.items
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(appointment);
