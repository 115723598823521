import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import validator from "validator";
import { usStates, canadaStates } from "../data/statesList";
import NumberFormat from "react-number-format";
import Select from "react-select";

import Header from "./header";
import Footer from "./footer";
import { USER_LOGOUT } from "../store/actions/auth";
import { ADD_CUSTOMER_INFO } from "../store/actions/infoAction";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class customerInfo extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      propertyName: "",
      firstName: "",
      lastName: "",
      country: "United States",
      street1: "",
      primaryPhone: "",
      street2: "",
      mobileNumber: "",
      state: "",
      email: "",
      city: "",
      zip: ""
    };
    this.blockState = {
      firstName: "none",
      firstNameSpec: "none",
      lastName: "none",
      lastNameSpec: "none",
      country: "none",
      street1: "none",
      primaryPhone: "none",
      street2: "none",
      mobileNumber: "none",
      phoneOptional: "none",
      state: "none",
      email: "none",
      city: "none",
      zip: "none",
      blankEmail: "none",
      blankZip: "none"
    };
    if (this.props.info.customerInfo !== null) {
      this.state = {
        ...this.props.info.customerInfo,
        blockState: this.blockState
      };
    } else {
      this.state = {
        ...this.initialState,
        blockState: this.blockState
      };
    }
  }

  onChange_state = key => {
    this.setState(state => {
      return {
        ...state,
        blockState: {
          ...this.blockState,
          [key]: "block"
        }
      };
    });
  };

  onNext_handler = () => {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (validator.isEmpty(this.state.firstName)) {
      document.getElementsByName("firstName")[0].focus();
      this.onChange_state("firstName");
      return;
    } else if (format.test(this.state.firstName)) {
      document.getElementsByName("firstName")[0].focus();
      this.onChange_state("firstNameSpec");
      return;
    } else if (validator.isEmpty(this.state.lastName)) {
      document.getElementsByName("lastName")[0].focus();
      this.onChange_state("lastName");
      return;
    } else if (format.test(this.state.lastName)) {
      document.getElementsByName("lastName")[0].focus();
      this.onChange_state("lastNameSpec");
      return;
    } else if (
      validator.isEmpty(this.state.mobileNumber) &&
      validator.isEmpty(this.state.primaryPhone)
    ) {
      // if (validator.isEmpty(this.state.primaryPhone)) {
      document.getElementsByName("primaryPhone")[0].focus();
      this.onChange_state("phoneOptional");
      return;
      // !validator.isMobilePhone(this.state.mobileNumber, "en-US")
    } else if (
      !validator.isEmpty(this.state.primaryPhone) &&
      !validator.isMobilePhone(this.state.primaryPhone, "en-US")
    ) {
      document.getElementsByName("primaryPhone")[0].focus();
      this.onChange_state("primaryPhone");
      return;
    } else if (
      !validator.isEmpty(this.state.mobileNumber) &&
      !validator.isMobilePhone(this.state.mobileNumber, "en-US")
    ) {
      document.getElementsByName("mobileNumber")[0].focus();
      this.onChange_state("mobileNumber");
      return;
    } else if (validator.isEmpty(this.state.street1)) {
      document.getElementsByName("street1")[0].focus();
      this.onChange_state("street1");
      return;
    } else if (validator.isEmpty(this.state.country)) {
      document.getElementsByName("country")[0].focus();
      this.onChange_state("country");
      return;
    } else if (validator.isEmpty(this.state.state)) {
      // document.getElementsByName("state")[0].focus();
      this.onChange_state("state");
      return;
    } else if (validator.isEmpty(this.state.email)) {
      document.getElementsByName("email")[0].focus();
      this.onChange_state("blankEmail");
      return;
    } else if (!validator.isEmail(this.state.email)) {
      document.getElementsByName("email")[0].focus();
      this.onChange_state("email");
      return;
    } else if (validator.isEmpty(this.state.city)) {
      document.getElementsByName("city")[0].focus();
      this.onChange_state("city");
      return;
    } else if (validator.isEmpty(this.state.zip)) {
      document.getElementsByName("zip")[0].focus();
      this.onChange_state("blankZip");
      return;
    }
    if (!validator.isPostalCode(this.state.zip, "any")) {
      document.getElementsByName("zip")[0].focus();
      this.onChange_state("zip");
      return;
    }

    // this.props.cookies.set("customerInfo", this.state);
    this.props.addCustomerInfo(
      this.state,
      this.props.auth.accountType,
      this.props.auth.firstName,
      this.props.auth.accountId
    );
    if (this.props.auth.accountType === "Realtor") {
      this.props.history.push("/area");
    } else this.props.history.push("/products");
  };

  onCancel_handler = () => {
    this.props.clearInfo();
    this.props.logOut();
    this.props.history.push("/");
  };

  onHelp_handler = e => {
    confirmAlert({
      title: "Help",
      message: "This is dummy text. 1234567890 abcdefghyjklmnopqrstuvwxyz",
      buttons: [
        {
          label: "Got it!"
        }
      ],
      closeOnEscape: true
    });
  };

  onZipChangeHandler = e => {
    var tagName = e.target.name;
    if (tagName === "zip") {
      if (this.state.country === "United States") {
        if (
          !validator.isNumeric(e.target.value) &&
          !validator.isEmpty(e.target.value)
        ) {
          return;
        }
      } else {
        // name.replace(/\s/g,'-'); // Javascript-jeep--
        var str = e.target.value;
        str = str.replace(/\s/g, "");
        var res = str.charAt(str.length - 1);
        if (str.length % 2 === 0) {
          if (!validator.isNumeric(res) && !validator.isEmpty(e.target.value)) {
            return;
          }
        } else {
          if (validator.isNumeric(res) && !validator.isEmpty(e.target.value)) {
            return;
          }
        }
        if (e.target.value.length > 3) {
          str = str.substr(0, 3) + " " + str.substr(3);
          this.setState({
            [e.target.name]: str
          });
          return;
        }
      }
    }
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onChange_handler = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleStatesChange = selectedOption => {
    this.setState({
      state: selectedOption.label
    });
  };

  handleChange = selectedOption => {
    // this.setState({ selectedOption });
    if (selectedOption.type === "country") {
      this.setState({
        zip: "",
        state: "",
        [selectedOption.type]: selectedOption.value
      });
      return;
    }
    this.setState({
      [selectedOption.type]: selectedOption.value
    });
  };

  render() {
    if (!this.props.auth.isLogin) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <Header />
        <section
          className="main-body"
          style={{ minHeight: "calc(100vh - 48px - 61px)" }}
        >
          <div className="container">
            <div className="screen-one">
              <div className="box box-primary" id="box">
                <div className="box-header with-border text-center">
                  <h3 className="box-title">
                    Enter Customer Contact Information
                  </h3>
                </div>
                <form className="">
                  <div className="box-body">
                    <div className="row">
                      <div className="col-sm-6 order-sm-1 order-1">
                        <div className="form-group">
                          <label className="label-style">Property Name:</label>
                          <input
                            tabIndex="1"
                            type="text"
                            className="form-control"
                            placeholder={
                              this.state.firstName !== ""
                                ? this.state.firstName + this.state.lastName
                                : "Property Name"
                            }
                            name={"propertyName"}
                            value={this.state.propertyName}
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 order-sm-1 order-2">
                        <div className="form-group">
                          <label className="label-style">
                            Country<span className="required"></span>:
                          </label>
                          {/* class={"form-control selectpicker"} */}
                          <Select
                            tabIndex="6"
                            placeholder={
                              this.state.country === ""
                                ? "United States"
                                : this.state.country
                            }
                            onChange={this.handleChange}
                            options={[
                              {
                                type: "country",
                                label: "United States",
                                value: "United States"
                              },
                              {
                                type: "country",
                                label: "Canada",
                                value: "Canada"
                              }
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 order-sm-1 order-1">
                        <div className="form-group">
                          <label className="label-style">
                            First Name<span className="required"></span>:
                          </label>
                          <input
                            tabIndex="1"
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name={"firstName"}
                            value={this.state.firstName}
                            onChange={this.onChange_handler}
                          />
                          <label
                            style={{
                              display: this.state.blockState.firstName,
                              color: "red"
                            }}
                          >
                            First Name is required
                          </label>
                          <label
                            style={{
                              display: this.state.blockState.firstNameSpec,
                              color: "red"
                            }}
                          >
                            {
                              "Don't use special characters like !@#$%^&*.,<>/'\";:?"
                            }
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-6 order-sm-1 order-2">
                        <div className="form-group">
                          <label className="label-style">
                            Street Address 1<span className="required"></span>:
                          </label>
                          <input
                            tabIndex="7"
                            type="text"
                            className="form-control"
                            placeholder="Street Address 1"
                            name={"street1"}
                            value={this.state.street1}
                            onChange={this.onChange_handler}
                          />
                          <label
                            style={{
                              display: this.state.blockState.street1,
                              color: "red"
                            }}
                          >
                            Street Address 1 is required
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-6 order-sm-1 order-1">
                        <div className="form-group">
                          <label className="label-style">
                            Last Name<span className="required"></span>:
                          </label>
                          <input
                            tabIndex="2"
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="lastName"
                            value={this.state.lastName}
                            onChange={this.onChange_handler}
                          />
                          <label
                            style={{
                              display: this.state.blockState.lastName,
                              color: "red"
                            }}
                          >
                            Last Name is required
                          </label>
                          <label
                            style={{
                              display: this.state.blockState.lastNameSpec,
                              color: "red"
                            }}
                          >
                            {
                              "Don't use special characters like !@#$%^&*.,<>/'\";:?"
                            }
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-6 order-sm-1 order-2">
                        <div className="form-group">
                          <label className="label-style">
                            Street Address 2:
                          </label>
                          <input
                            tabIndex="8"
                            type="text"
                            className="form-control"
                            placeholder="Street Address 2"
                            name={"street2"}
                            value={this.state.street2}
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 order-sm-1 order-1">
                        <div className="form-group">
                          <label className="label-style">Primary Phone:</label>
                          {/* <input
                            type="text"
                            pattern="\d*"
                            maxLength="10"
                            className="form-control"
                            placeholder="Primary Phone"
                            name={"primaryPhone"}
                            value={this.state.primaryPhone}
                            onChange={this.onChange_handler}
                          /> */}
                          <NumberFormat
                            tabIndex="3"
                            format="(###) ###-####"
                            mask="_"
                            inputMode="numeric"
                            className="form-control"
                            name={"primaryPhone"}
                            placeholder={"Primary Phone Number"}
                            value={this.state.primaryPhone}
                            onChange={this.onChange_handler}
                          />
                          <label
                            style={{
                              display: this.state.blockState.primaryPhone,
                              color: "red"
                            }}
                          >
                            Primary phone number is invalid.
                          </label>
                          <label
                            style={{
                              display: this.state.blockState.phoneOptional,
                              color: "red"
                            }}
                          >
                            //Primary or Mobile number is mandatory.
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-6 order-sm-1 order-2">
                        <div className="form-group">
                          <label className="label-style">
                            {this.state.country === "United States"
                              ? "State"
                              : "Province"}
                            <span className="required"></span>:
                          </label>
                          {/* class={"form-control selectpicker"} */}
                          <Select
                            tabIndex="9"
                            // className={"selectpicker"}
                            // defaultInputValue={'United States'}
                            placeholder={
                              this.state.state === ""
                                ? "Select any one.."
                                : this.state.state
                            }
                            onChange={this.handleStatesChange}
                            options={
                              this.state.country === "United States"
                                ? usStates.states
                                : canadaStates.states
                            }
                          />
                          <label
                            style={{
                              display: this.state.blockState.state,
                              color: "red"
                            }}
                          >
                            Please select
                            {this.state.country === "United States"
                              ? " States "
                              : " Province"}
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-6 order-sm-1 order-1">
                        <div className="form-group">
                          <label className="label-style">Mobile Number:</label>
                          {/* <input
                            type="text"
                            pattern="\d*"
                            maxLength="10"
                            className="form-control"
                            placeholder="Mobile Number"
                            name={"mobileNumber"}
                            value={this.state.mobileNumber}
                            onChange={this.onChange_handler}
                          /> */}
                          <NumberFormat
                            tabIndex="4"
                            format="(###) ###-####"
                            mask="_"
                            inputMode="numeric"
                            className="form-control"
                            placeholder={"Mobile Number"}
                            name={"mobileNumber"}
                            value={this.state.mobileNumber}
                            onChange={this.onChange_handler}
                          />
                          <label
                            style={{
                              display: this.state.blockState.mobileNumber,
                              color: "red"
                            }}
                          >
                            Mobile Number is invalid.
                          </label>
                          <label
                            style={{
                              display: this.state.blockState.phoneOptional,
                              color: "red"
                            }}
                          >
                            //Primary or Mobile number is mandatory.
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-6 order-sm-1 order-2">
                        <div className="form-group">
                          <label className="label-style">
                            City<span className="required"></span>:
                          </label>
                          <input
                            tabIndex="10"
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name={"city"}
                            value={this.state.city}
                            onChange={this.onChange_handler}
                          />
                          <label
                            style={{
                              display: this.state.blockState.city,
                              color: "red"
                            }}
                          >
                            Please enter city.
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-6 order-sm-1 order-1">
                        <div className="form-group">
                          <label className="label-style">
                            Email<span className="required"></span>:
                          </label>
                          <input
                            tabIndex="5"
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            name={"email"}
                            value={this.state.email}
                            onChange={this.onChange_handler}
                          />
                          <label
                            style={{
                              display: this.state.blockState.email,
                              color: "red"
                            }}
                          >
                            Email is invalid.
                          </label>
                          <label
                            style={{
                              display: this.state.blockState.blankEmail,
                              color: "red"
                            }}
                          >
                            Please enter email address.
                          </label>
                        </div>
                      </div>

                      {/* <div className="col-sm-6 order-sm-1 order-1 d-none d-sm-block">
                        <div className="form-group">
                          <label className="label-style"></label>
                        </div>
                      </div> */}
                      <div className="col-sm-6 order-sm-1 order-2">
                        <div className="form-group">
                          <label className="label-style">
                            {this.state.country === "United States"
                              ? "Zip"
                              : "Postal Code"}
                            <span className="required"></span>:
                          </label>
                          <input
                            tabIndex="11"
                            autoComplete="off"
                            type={"text"}
                            inputMode={
                              this.state.country === "United States"
                                ? "numeric"
                                : "text"
                            }
                            maxLength={
                              this.state.country === "United States" ? "5" : "7"
                            }
                            className="form-control"
                            placeholder={
                              this.state.country === "United States"
                                ? "Zip"
                                : "Postal Code"
                            }
                            name={"zip"}
                            value={this.state.zip}
                            onChange={this.onZipChangeHandler}
                          />
                          <label
                            style={{
                              display: this.state.blockState.blankZip,
                              color: "red"
                            }}
                          >
                            Please Enter{" "}
                            {this.state.country === "United States"
                              ? "Zip."
                              : "Postal Code."}
                          </label>
                          <label
                            style={{
                              display: this.state.blockState.zip,
                              color: "red"
                            }}
                          >
                            Invalid{" "}
                            {this.state.country === "United States"
                              ? "Zip."
                              : "Postal Code."}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer with-border">
                    <div className="">
                      <ul className="custom-list">
                        <div></div>
                        <li
                          className="list-inline-item"
                          style={{ marginLeft: "100px" }}
                        >
                          <input
                            tabIndex="14"
                            type="button"
                            className="btn btn-primary"
                            value="Cancel"
                            onClick={this.onCancel_handler}
                            id="prev-s0"
                          />
                        </li>
                        <li className="list-inline-item">
                          <input
                            tabIndex="13"
                            type="button"
                            className="btn btn-primary"
                            value="Next"
                            onClick={this.onNext_handler}
                            id="next-s2"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log('customerInfo = ' , state);
  return {
    auth: state.auth,
    info: state.items
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addCustomerInfo: (data, accountType, userName, accountId) => {
      dispatch({
        type: ADD_CUSTOMER_INFO,
        data: data,
        accountType: accountType,
        userName: userName,
        accountId: accountId
      });
    },
    logOut: () => {
      dispatch({
        type: USER_LOGOUT
      });
    },
    clearInfo: () => {
      dispatch({
        type: "CLEAR_INFO"
      });
    },
    setCookieState: data => {
      dispatch({
        type: "setFromCookie",
        data: data
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(customerInfo);
