import React from "react";
import { USER_LOGIN, USER_LOGOUT } from "../actions/auth";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { Link, Redirect } from "react-router-dom";

const initialState = {
  isLogin: false,
  firstName: null,
  accountType: null,
  accountId: null,
  sessionToken: null,
  sessionTime: null,
  userInfo: null,
};

let timer = null;

export const clearTimer = () => {
  if (timer !== null) {
    console.log("timer cleared");
    clearTimeout(timer);
  }
  localStorage.removeItem("projectId");
  localStorage.removeItem("userAuth");
  localStorage.removeItem("expirationDate");
};

// const setCookie = () => {
//   var value = "; " + document.cookie;
//   var parts = value.split("; " + "userAuth" + "=");
//   var date = new Date();
//   date.setTime(date.getTime() + 5 * 60 * 1000);
//   var expires = "; expires=" + date.toGMTString();
//   if (parts.length == 2) {
//     document.cookie =
//       "userAuth = " +
//       parts
//         .pop()
//         .split(";")
//         .shift() +
//       "; Max-Age=" +
//       expires +
//       ";";
//   } else document.cookie = "userAuth = " + parts + "; Max-Age=" + expires + ";";
// };

const setExpirationDate = () => {
  var exp = new Date();
  exp.setMinutes(exp.getMinutes() + 20);
  //exp.setMinutes(exp.getMinutes() + 720);
  console.log('exp Time = ' , exp.getMinutes);
  localStorage.setItem("expirationDate", exp.getTime());
};

export const setTimer = () => {
  let oldExp = localStorage.getItem("expirationDate");
  if (oldExp) {
    let now = new Date();
    oldExp = +oldExp;
    let diff = oldExp - now.getTime();
    if (diff <= 0) {
      clearTimer();
      window.history.go(0);
      return;
    }
  }
  setExpirationDate();
  timer = setTimeout(() => {
    //clearTimer();
    //window.history.go('http://measuremaker.io');
    //window.location.assign("http://measuremaker.io");
    confirmAlert({
      title: <h5>Session Expire Soon...</h5>,
      message: "Your session is about to expire due to inactivity",
      buttons: [
        {
          label: "Continue",
          onClick: () => {
            setExpirationDate();
            setTimer();
          }
        },
        {
          label: "Logout",
          onClick: () => {
            clearTimer();
            axios
              .post("https://www.floorsoft.com/FloorWizard/fwapi/session/logoff", {
                sessionStatus: "Session Logon Status Request Log Off",
                sessionToken: initialState.sessionToken,
                lstMessages: null,
                sessionTime: initialState.sessionTime
              })
              .then(res => { window.location.assign("http://measuremaker.io"); })
              .catch(e  => { console.log(e);                                   });
          }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: false
    });
  //}, 10000 * 60 * 20 - 1000 * 30); // about 100 hrs
  }, 1000 * 60 * 20 - 1000 * 30); // about 10 hrs
  //}, 30000);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      state = {
        isLogin: true,
        accountId: action.accountId,
        accountName: action.accountName,
        accountType: action.accountType,
        firstName: action.firstName,
        sessionToken: action.sessionToken,
        sessionTime: action.sessionTime,
        userInfo: action.userInfo
      };
      break;
    case USER_LOGOUT:
      clearTimer();
      axios
        .post("https://www.floorsoft.com/FloorWizard/fwapi/session/logoff", {
          sessionStatus: "Session Logon Status Request Log Off",
          sessionToken: state.sessionToken,
          lstMessages: null,
          sessionTime: state.sessionTime
        })
        .then(res => {})
        .catch(e => {
          console.log(e);
        });
      return initialState;
    default:
      return state;
  }
  return state;
};
