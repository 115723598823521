import {
  ADD_APPOINTMENT,
  ADD_AREA_INFO,
  ADD_CUSTOMER_INFO,
  ADD_DATE,
  ADD_PRODUCT_TYPE,
  ADD_INSTRUCTION,
  ADD_REMINDER,
  ADD_ROOM_INFO,
  CLEAR_INFO
} from "../actions/infoAction";

const initialState = {
  accountType: null,
  userName: null,
  accountId: null,
  customerInfo: null,
  productType: null,
  areas: null,
  roomInformation: [],
  rooms: [],
  appointment: null,
  appointmentDate: null,
  reminder: [],
  instruction: "",
  gateCode:"",
  lockBoxCode:""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER_INFO:
      const data = { ...action.data };
      delete data.blockState;
      const newCustState = {
        ...state,
        customerInfo: action.data,
        accountType: action.accountType,
        userName: action.userName,
        accountId: action.accountId
      };
      localStorage.setItem(
        "state" + action.accountId,
        JSON.stringify(newCustState)
      );

      return newCustState;
    case ADD_PRODUCT_TYPE:
      const newProdState = { ...state, productType: action.data };
      localStorage.setItem(
        "state" + state.accountId,
        JSON.stringify(newProdState)
      );

      return newProdState;
    case ADD_ROOM_INFO:
      const rooms = state.rooms;
      action.rooms.forEach(element => {
        rooms.push(element);
      });
      let newRoomState = {
        ...state,
        rooms: rooms
      };
      const pos = newRoomState.roomInformation.findIndex(
        (item, index) => item.productType === state.productType
      );
      if (pos !== -1) {
        newRoomState.roomInformation[pos].totalRooms += action.totalRooms;
        localStorage.setItem(
          "state" + state.accountId,
          JSON.stringify(newRoomState)
        );
        return newRoomState;
      }
      newRoomState = {
        ...state,
        roomInformation: [
          ...state.roomInformation,
          {
            productType: state.productType,
            totalRooms: action.totalRooms
          }
        ]
      };
      localStorage.setItem(
        "state" + state.accountId,
        JSON.stringify(newRoomState)
      );
      return newRoomState;

    case ADD_APPOINTMENT:
      const newState = { ...state, appointment: action.data };
      localStorage.setItem("state" + state.accountId, JSON.stringify(newState));
      return newState;
    case ADD_DATE:
      const newDateState = { ...state, appointmentDate: action.data };
      localStorage.setItem(
        "state" + state.accountId,
        JSON.stringify(newDateState)
      );

      return newDateState;
    case ADD_INSTRUCTION:
      const newInstState = { ...state, instruction: action.data.instruction, 
                                       gateCode: action.data.gateCode, 
                                       lockBoxCode: action.data.lockBoxCode 
                           };
      localStorage.setItem(
        "state" + state.accountId,
        JSON.stringify(newInstState)
      );

      return newInstState;
    case ADD_REMINDER:
      const newRemState = { ...state, reminder: action.data };
      localStorage.setItem(
        "state" + state.accountId,
        JSON.stringify(newRemState)
      );

      return newRemState;
    case ADD_AREA_INFO:
      const newAreaInfoState = { ...state, areas: action.data };
      localStorage.setItem(
        "state" + state.accountId,
        JSON.stringify(newAreaInfoState)
      );

      return newAreaInfoState;
    case CLEAR_INFO:
      localStorage.removeItem("state" + state.accountId);
      localStorage.removeItem("projectId");
      return initialState;
    case "setFromCookie":
      const newDataState = action.data;
      return newDataState;
    default:
      return state;
  }
};
