import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Header from "./header";
import Footer from "./footer";

class measuringList extends Component {
  onSetAppointment_handler = () => {
    this.props.items.appointment = null;
    this.props.history.push("/appointment-time");
  };
  onCreateProject_handler = () => {
    this.props.items.appointmentDate = null;
    this.props.items.appointment = "Not Set";
    this.props.history.push("/instruction");
  };
  addRoom_handler = () => {
    this.props.history.push("/products");
  };
  onCancel_handler = () => {
    this.props.logOut();
    this.props.history.push("/");
    // this.props.history.push("/customer-information");
  };
  onBack_handler = () => {
    this.props.history.push("/room-information");
  };
  render() {
    if (!this.props.auth.isLogin) {
      return <Redirect to="/" />;
    } else {
      if (this.props.auth.accountType === "Realtor")
        return <Redirect to="/customer-information" />;
    }

    let tableInfo = null;

    if (this.props.items.roomInformation.length === 0)
      return <Redirect to="/products" />;

    tableInfo = this.props.items.roomInformation.map((item, i) => (
      <tr key={i}>
        <td>{item.productType}</td>
        <td>{item.totalRooms} Rooms</td>
      </tr>
    ));
    return (
      <div>
        <Header />
        <section
          className="main-body"
          style={{ minHeight: "calc(100vh - 48px - 61px)" }}
        >
          <div className="container">
            <div className="screen-four">
              <div className="box box-primary">
                <div className="box-header with-border text-center">
                  <h3 className="box-title">We will be measuring for:</h3>
                </div>
                <form className="">
                  <div className="box-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <table className="table table-bordered mb-5">
                          <thead>
                            <tr>
                              <th className="text-center">Flooring</th>
                              <th className="text-center">Rooms</th>
                            </tr>
                          </thead>
                          <tbody>{tableInfo}</tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3 form-group">
                        <input
                          type="button"
                          className="btn btn-primary btn-block"
                          value="Add More Rooms / Flooring"
                          onClick={this.addRoom_handler}
                          id="prev-s2_1"
                        />
                      </div>
                      <div className="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3 form-group">
                        <input
                          type="button"
                          className="btn btn-primary btn-block"
                          value="Set a Measure Appointment"
                          onClick={this.onSetAppointment_handler}
                          id="next-s5"
                        />
                      </div>
                      
                        <div className="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3 form-group">
                          <input
                            type="button"
                            className="btn btn-primary btn-block"
                            value="Measurer Will Call to Set Appointment"
                            onClick={this.onCreateProject_handler}
                            id="next-s5"
                          />
                        </div>
                      
                    </div>
                  </div>
                  <div className="box-footer with-border">
                    <div className="">
                      <ul className="custom-list">
                        <li className="list-inline-item">
                          <input
                            type="button"
                            className="btn btn-primary"
                            value="Back"
                            onClick={this.onBack_handler}
                            id="prev-s11"
                          />
                        </li>
                        <li
                          className="list-inline-item"
                          style={{ marginRight: "90px" }}
                        >
                          <input
                            type="button"
                            className="btn btn-primary"
                            value="Cancel"
                            onClick={this.onCancel_handler}
                            id="prev-s11"
                          />
                        </li>
                        <div></div>
                        {/* <li className="list-inline-item">
                          <input
                            type="button"
                            className="btn btn-primary btn-block"
                            value="Set a Measure Appointment"
                            onClick={this.onSetAppointment_handler}
                            id="next-s5"
                          />
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <div style={{ position: "absolute", width: "100%", bottom: 0 }}> */}
        <Footer />
        {/* </div> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    items: state.items
  };
};

export default connect(mapStateToProps)(measuringList);
