import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";

import { ADD_ROOM_INFO } from "../store/actions/infoAction";
import Header from "./header";
import Footer from "./footer";

class roomInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backsplash: 0,
      decorativeWall: 0,
      greatRoom: 0,
      masterBadRoom: 0,
      staircase: 0,
      balcony: 0,
      den: 0,
      garage: 0,
      nursery: 0,
      storeRoom: 0,
      basement: 0,
      diningRoom: 0,
      hallway: 0,
      office: 0,
      theaterRoom: 0,
      bathroom: 0,
      familyRoom: 0,
      laundry: 0,
      patio: 0,
      waitRoom: 0,
      closet: 0,
      bedroom: 0,
      foyer: 0,
      livingRoom: 0,
      poolArea: 0,
      workShop: 0,
      deck: 0,
      gameRoom: 0,
      lobby: 0,
      shower: 0,
      other: 0,
      entry: 0,
      kitchen: 0,
      porch: 0,
      utilityRoom: 0
    };
  }

  onNext_handler = () => {
    let totalRooms = 0;
    const rooms = [];
    for (var prop in this.state) {
      if (!isNaN(parseInt(this.state[prop]))) {
        totalRooms += parseInt(this.state[prop]);
        if (this.state[prop] !== 0) {
          rooms.push({
            roomType: prop,
            productType: this.props.cookies.get("selectedItem"),
            roomCount: this.state[prop]
          });
        }
      }
    }
    // {this.props.cookies.get("selectedItem")}
    this.props.addRoomInfo(this.state, totalRooms, rooms);
    // console.log(totalRooms)
    this.props.history.push("/measurment-list");
  };

  onBack_handler = () => {
    this.props.history.push("/products");
  };

  onClear_handler = () => {
    const initialState = {
      backsplash: 0,
      decorativeWall: 0,
      greatRoom: 0,
      masterBadRoom: 0,
      staircase: 0,
      balcony: 0,
      den: 0,
      garage: 0,
      nursery: 0,
      storeRoom: 0,
      basement: 0,
      diningRoom: 0,
      hallway: 0,
      office: 0,
      theaterRoom: 0,
      bathroom: 0,
      familyRoom: 0,
      laundry: 0,
      patio: 0,
      waitRoom: 0,
      closet: 0,
      bedroom: 0,
      foyer: 0,
      livingRoom: 0,
      poolArea: 0,
      workShop: 0,
      deck: 0,
      gameRoom: 0,
      lobby: 0,
      shower: 0,
      other: 0,
      entry: 0,
      kitchen: 0,
      porch: 0,
      utilityRoom: 0
    };
    this.setState(state => {
      return initialState;
    });
  };

  onChange_handler = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    if (this.props.auth.accountType === "Realtor") {
      return <Redirect to="/customer-information" />;
    }
    if (!this.props.cookies.get("selectedItem"))
      return <Redirect to="/products" />;
    return (
      <div style={{ minHeight: "100vh" }}>
        <Header />
        <section
          className="main-body"
          style={{ minHeight: "calc(100vh - 48px - 61px)" }}
        >
          <div className="container">
            <div className="screen-three">
              <div className="box box-primary">
                <div className="box-header with-border text-center">
                  <h3 className="box-title">Room Information</h3>
                </div>
                <form className="">
                  <div className="box-body">
                    <h4 className="box-subtitle">
                      How Many Rooms will be Measured for{" "}
                      {this.props.cookies.get("selectedItem")}
                      <span id="selectedFloorProduct"></span> ?
                    </h4>

                    <div className="row">
                      <div className="col-20 order-sm-1 order-1 cw-100">
                        <div className="form-group">
                          <label className="label-style">Backsplash :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={1}
                            className="form-control"
                            placeholder="0"
                            name={"backsplash"}
                            value={
                              this.state.backsplash === 0
                                ? ""
                                : this.state.backsplash
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-2 cw-100">
                        <div className="form-group">
                          <label className="label-style">
                            Decorative Wall :
                          </label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={8}
                            className="form-control"
                            placeholder="0"
                            name={"decorativeWall"}
                            value={
                              this.state.decorativeWall === 0
                                ? ""
                                : this.state.decorativeWall
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-3 cw-100">
                        <div className="form-group">
                          <label className="label-style">Great Room :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={15}
                            className="form-control"
                            placeholder="0"
                            name={"greatRoom"}
                            value={
                              this.state.greatRoom === 0
                                ? ""
                                : this.state.greatRoom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-4 cw-100">
                        <div className="form-group">
                          <label className="label-style">
                            Master Bed Room :
                          </label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={22}
                            className="form-control"
                            placeholder="0"
                            name={"masterBadRoom"}
                            value={
                              this.state.masterBadRoom === 0
                                ? ""
                                : this.state.masterBadRoom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-5 cw-100">
                        <div className="form-group">
                          <label className="label-style">Staircase :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={29}
                            className="form-control"
                            placeholder="0"
                            name={"staircase"}
                            value={
                              this.state.staircase === 0
                                ? ""
                                : this.state.staircase
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>

                      <div className="col-20 order-sm-1 order-1 cw-100">
                        <div className="form-group">
                          <label className="label-style">Balcony :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={2}
                            className="form-control"
                            placeholder="0"
                            name={"balcony"}
                            value={
                              this.state.balcony === 0 ? "" : this.state.balcony
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-2 cw-100">
                        <div className="form-group">
                          <label className="label-style">Den :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={9}
                            className="form-control"
                            placeholder="0"
                            name={"den"}
                            value={this.state.den === 0 ? "" : this.state.den}
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-3 cw-100">
                        <div className="form-group">
                          <label className="label-style">Garage :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={16}
                            className="form-control"
                            placeholder="0"
                            name={"garage"}
                            value={
                              this.state.garage === 0 ? "" : this.state.garage
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-4 cw-100">
                        <div className="form-group">
                          <label className="label-style">Nursery :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={23}
                            className="form-control"
                            placeholder="0"
                            name={"nursery"}
                            value={
                              this.state.nursery === 0 ? "" : this.state.nursery
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-5 cw-100">
                        <div className="form-group">
                          <label className="label-style">Store Room :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={30}
                            className="form-control"
                            placeholder="0"
                            name={"storeRoom"}
                            value={
                              this.state.storeRoom === 0
                                ? ""
                                : this.state.storeRoom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>

                      <div className="col-20 order-sm-1 order-1 cw-100">
                        <div className="form-group">
                          <label className="label-style">Basement :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={3}
                            className="form-control"
                            placeholder="0"
                            name={"basement"}
                            value={
                              this.state.basement === 0
                                ? ""
                                : this.state.basement
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-2 cw-100">
                        <div className="form-group">
                          <label className="label-style">Dining Room :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={10}
                            className="form-control"
                            placeholder="0"
                            name={"diningRoom"}
                            value={
                              this.state.diningRoom === 0
                                ? ""
                                : this.state.diningRoom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-3 cw-100">
                        <div className="form-group">
                          <label className="label-style">Hallway :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={17}
                            className="form-control"
                            placeholder="0"
                            name={"hallway"}
                            value={
                              this.state.hallway === 0 ? "" : this.state.hallway
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-4 cw-100">
                        <div className="form-group">
                          <label className="label-style">Office :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={24}
                            className="form-control"
                            placeholder="0"
                            name={"office"}
                            value={
                              this.state.office === 0 ? "" : this.state.office
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-5 cw-100">
                        <div className="form-group">
                          <label className="label-style">Theater Room :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={31}
                            className="form-control"
                            placeholder="0"
                            name={"theaterRoom"}
                            value={
                              this.state.theaterRoom === 0
                                ? ""
                                : this.state.theaterRoom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>

                      <div className="col-20 order-sm-1 order-1 cw-100">
                        <div className="form-group">
                          <label className="label-style">Bathroom :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={4}
                            className="form-control"
                            placeholder="0"
                            name={"bathroom"}
                            value={
                              this.state.bathroom === 0
                                ? ""
                                : this.state.bathroom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-2 cw-100">
                        <div className="form-group">
                          <label className="label-style">Entry :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={11}
                            className="form-control"
                            placeholder="0"
                            name={"entry"}
                            value={
                              this.state.entry === 0 ? "" : this.state.entry
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-3 cw-100">
                        <div className="form-group">
                          <label className="label-style">Kitchen :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={18}
                            className="form-control"
                            placeholder="0"
                            name={"kitchen"}
                            value={
                              this.state.kitchen === 0 ? "" : this.state.kitchen
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-4 cw-100">
                        <div className="form-group">
                          <label className="label-style">Porch :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={25}
                            className="form-control"
                            placeholder="0"
                            name={"porch"}
                            value={
                              this.state.porch === 0 ? "" : this.state.porch
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-5 cw-100">
                        <div className="form-group">
                          <label className="label-style">Utility Room :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={32}
                            className="form-control"
                            placeholder="0"
                            name={"utilityRoom"}
                            value={
                              this.state.utilityRoom === 0
                                ? ""
                                : this.state.utilityRoom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>

                      <div className="col-20 order-sm-1 order-1 cw-100">
                        <div className="form-group">
                          <label className="label-style">Bedroom :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={5}
                            className="form-control"
                            placeholder="0"
                            name={"bedroom"}
                            value={
                              this.state.bedroom === 0 ? "" : this.state.bedroom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-2 cw-100">
                        <div className="form-group">
                          <label className="label-style">Family Room :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={12}
                            className="form-control"
                            placeholder="0"
                            name={"familyRoom"}
                            value={
                              this.state.familyRoom === 0
                                ? ""
                                : this.state.familyRoom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-3 cw-100">
                        <div className="form-group">
                          <label className="label-style">Laundry :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={19}
                            className="form-control"
                            placeholder="0"
                            name={"laundry"}
                            value={
                              this.state.laundry === 0 ? "" : this.state.laundry
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-4 cw-100">
                        <div className="form-group">
                          <label className="label-style">Patio :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={26}
                            className="form-control"
                            placeholder="0"
                            name={"patio"}
                            value={
                              this.state.patio === 0 ? "" : this.state.patio
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-5 cw-100">
                        <div className="form-group">
                          <label className="label-style">Wait Room :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={33}
                            className="form-control"
                            placeholder="0"
                            name={"waitRoom"}
                            value={
                              this.state.waitRoom === 0
                                ? ""
                                : this.state.waitRoom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>

                      <div className="col-20 order-sm-1 order-1 cw-100">
                        <div className="form-group">
                          <label className="label-style">Closet :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={6}
                            className="form-control"
                            placeholder="0"
                            name={"closet"}
                            value={
                              this.state.closet === 0 ? "" : this.state.closet
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-2 cw-100">
                        <div className="form-group">
                          <label className="label-style">Foyer :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={13}
                            className="form-control"
                            placeholder="0"
                            name={"foyer"}
                            value={
                              this.state.foyer === 0 ? "" : this.state.foyer
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-3 cw-100">
                        <div className="form-group">
                          <label className="label-style">Living Room :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={20}
                            className="form-control"
                            placeholder="0"
                            name={"livingRoom"}
                            value={
                              this.state.livingRoom === 0
                                ? ""
                                : this.state.livingRoom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-4 cw-100">
                        <div className="form-group">
                          <label className="label-style">Pool Area :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={27}
                            className="form-control"
                            placeholder="0"
                            name={"poolArea"}
                            value={
                              this.state.poolArea === 0
                                ? ""
                                : this.state.poolArea
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-5 cw-100">
                        <div className="form-group">
                          <label className="label-style">Workshop :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={34}
                            className="form-control"
                            placeholder="0"
                            name={"workShop"}
                            value={
                              this.state.workShop === 0
                                ? ""
                                : this.state.workShop
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>

                      <div className="col-20 order-sm-1 order-1 cw-100">
                        <div className="form-group">
                          <label className="label-style">Deck :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={7}
                            className="form-control"
                            placeholder="0"
                            name={"deck"}
                            value={this.state.deck === 0 ? "" : this.state.deck}
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-2 cw-100">
                        <div className="form-group">
                          <label className="label-style">Game Room :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={14}
                            className="form-control"
                            placeholder="0"
                            name={"gameRoom"}
                            value={
                              this.state.gameRoom === 0
                                ? ""
                                : this.state.gameRoom
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-3 cw-100">
                        <div className="form-group">
                          <label className="label-style">Lobby :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={21}
                            className="form-control"
                            placeholder="0"
                            name={"lobby"}
                            value={
                              this.state.lobby === 0 ? "" : this.state.lobby
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-4 cw-100">
                        <div className="form-group">
                          <label className="label-style">Shower :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={28}
                            className="form-control"
                            placeholder="0"
                            name={"shower"}
                            value={
                              this.state.shower === 0 ? "" : this.state.shower
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                      <div className="col-20 order-sm-1 order-5 cw-100">
                        <div className="form-group">
                          <label className="label-style">Other :</label>
                          <input
                            type="number"
                            min={0}
                            tabIndex={35}
                            className="form-control"
                            placeholder="0"
                            name={"other"}
                            value={
                              this.state.other === 0 ? "" : this.state.other
                            }
                            onChange={this.onChange_handler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer with-border">
                    <div className="">
                      <ul className="custom-list">
                        <li className="list-inline-item">
                          <input
                            type="button"
                            className="btn btn-primary"
                            value="Back"
                            onClick={this.onBack_handler}
                            id="prev-s2"
                          />
                        </li>
                        <li className="list-inline-item">
                          <input
                            type="reset"
                            className="btn btn-primary"
                            value="Clear"
                            onClick={this.onClear_handler}
                          />
                        </li>
                        <li className="list-inline-item">
                          <input
                            type="button"
                            className="btn btn-primary"
                            onClick={this.onNext_handler}
                            value="Next"
                            id="next-s4"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    product: state.items
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addRoomInfo: (data, totalRooms, rooms) => {
      dispatch({
        type: ADD_ROOM_INFO,
        data: data,
        totalRooms: totalRooms,
        rooms: rooms
      });
    },
    logOut: () => {
      dispatch({
        type: "USER_LOGOUT"
      });
    },
    login: (
      firstName,
      accountType,
      accountName,
      accountId,
      sessionToken,
      sessionTime
    ) => {
      dispatch({
        type: "USER_LOGIN",
        firstName: firstName,
        accountType: accountType,
        accountName: accountName,
        accountId: accountId,
        sessionToken: sessionToken,
        sessionTime: sessionTime
      });
    },
    setCookieState: data => {
      dispatch({
        type: "setFromCookie",
        data: data
      });
    }
  };
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(roomInfo)
);
