import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { clearTimer } from "../store/reducers/authReducer";
import Header from "./header";

class conformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false
    };
  }

  onScheduleAnother = () => {
    //this.props.history.push("/calendar");
    console.log("Schedule another Apmt");
    this.props.clearInfo();
    this.props.history.push("/customer-information");
  };

  onCancel_handler = () => {
    console.log("cancel");
    this.props.clearInfo();
    this.props.logOut();
    this.props.history.push("/");
  };

  render() {
    if (!this.props.auth.isLogin) {
      return <Redirect to="/" />;
    }
    // if (this.props.auth.accountType === "Realtor" && !this.props.items.areas) {
    //   return <Redirect to="/area" />;
    // }
    // if (
    //   this.props.auth.accountType === "Retailer" &&
    //   this.props.items.roomInformation.length === 0
    // )
    //   return <Redirect to="/products" />;
    // if (!this.props.items.appointment) {
    //   return <Redirect to="/appointment-time" />;
    // }
    // if (!this.props.items.appointmentDate) {
    //   return <Redirect to="/calendar" />;
    // }

    return (
      <div>
        <Header />
        <section className="main-body">
          <div className="container">
            <div className="screen-sevan">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="box box-primary">
                    <form className="">
                      <div className="box-header with-border text-center">
                        <h3 className="box-title">
                          Thanks for scheduling an appointment with ProMeasuring!
                        </h3>
                      </div>
                      <div className="box-body">
                        {/* <h4 className="text-center"></h4> */}
                        <p className="text-center">
                          Someone from our measuring team will contact you
                          within the next 24 hours to confirm your appointment.
                        </p>
                      </div>
                      <div className="box-footer with-border">
                        {/*<h2 className="text-center">
                          {this.state.loading ? "Please wait..." : null}
                        </h2>*/}

                        <div className="d-flex justify-content-center text-center">
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Finish"
                                onClick={this.onCancel_handler}
                                id="next-s11"
                              />
                            </li>
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Set Another Appointment"
                                onClick={this.onScheduleAnother}
                                id="prev-sc1"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => {
      dispatch({
        type: "USER_LOGOUT"
      });
    },
    clearInfo: () => {
      dispatch({
        type: "CLEAR_INFO"
      });
    },
    setCookieState: data => {
      dispatch({
        type: "setFromCookie",
        data: data
      });
    }
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    items: state.items
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(conformation);
