import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import momenttz from "moment-timezone";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { USER_LOGIN } from "../store/actions/auth";
import { setTimer } from "../store/reducers/authReducer";

class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      password: "",
      loading: false,
      error: false,
      inValid: false
    };
  }

  handle_login = async () => {
    this.setState({
      loading: true,
      error: false,
      inValid: false
    });
    var currentDate = new Date().toUTCString();
    var myTimezone = "America/Toronto";
    var myDatetimeFormat= "YYYY-MM-DD hh:mm:ss A z";
    var myDatetimeString = moment(currentDate).tz(moment.tz.guess()).format(myDatetimeFormat);
    await axios
      .post("https://www.floorsoft.com/FloorWizard/fwapi/session/logon", {
        sessionStatus: "Session Logon Status Request Log On",
        sessionToken: null,
        sessionAction: null,
        lstMessages: null,
        userId: null,
        accessLevel: null,
        sessionLogon: this.state.userId,
        sessionPassword: this.state.password,
        sessionTime: myDatetimeString,
        // sessionTime: new Date().toUTCString(),
        sessionComponent: "Session Project Access Component Measure Maker",
        computerName: "MeasureMakerClient",
        computerAddress: "192.168.56.1",
        softwareVersion: "",
        active: null,
        userInfo: null
      })
      .then(res => {
        let data = res.data;
        if (data.userInfo === null) {
          this.setState({
            inValid: "Wrong credentials",
            loading: false
          });
          return;
        }
        let firstName = data.userInfo.firstName;
        let accountType = data.userInfo.account.accountType;
        let accountId = data.userInfo.account.accountId;
        let accountName = data.userInfo.account.accountName;
        let sessionToken = data.sessionToken;
        let sessionTime = data.sessionTime;
        let userInfo = data.userInfo;
        const cookieData = {
          firstName,
          accountType,
          accountName,
          accountId,
          sessionToken,
          sessionTime,
          userInfo
        };
        localStorage.setItem("userAuth", JSON.stringify(cookieData));
        this.props.login(
          firstName,
          accountType,
          accountName,
          accountId,
          sessionToken,
          sessionTime,
          userInfo
        );
        setTimer();
        this.props.history.push("/customer-information");
        // cookies.set("userAuth", cookieData, { path: "/", maxAge: 300 });
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: e,
          loading: false
        });
      });
    // this.props.history.push("/customer-information");
  };

  onChangeHandler = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onEnterKey=(event)=> {
    if (event.keyCode === 13) {
      this.handle_login();
    }
  }

  render = () => {
    if (this.props.auth.isLogin) {
      return <Redirect to="/customer-information" />;
    }
    return (
      <div>
        <section className="main-body">
          <div className="login-box">
            <div className="login-logo">
              <b />
              FloorWizard <br />
              Measure Maker
            </div>
            <div className="login-box-body">
              <p className="login-box-msg">Sign in to start your session</p>

              <form>
                <div className="form-group has-feedback">
                  <input
                    type="email"
                    className="form-control"
                    name="userId"
                    placeholder="User Name"
                    onChange={this.onChangeHandler}
                    onKeyDown={(e) => this.onEnterKey(e) }
                    value={this.state.userId}
                  />
                  <span className="ic ic-mail form-control-feedback"></span>
                </div>
                <div className="form-group has-feedback">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={this.onChangeHandler}
                    onKeyDown={(e) => this.onEnterKey(e) }
                  />
                  <span className="ic ic-lock form-control-feedback"></span>
                </div>

                <div className="row">
                  <div className="col-4">
                    <input
                      type="button"
                      value={
                        this.state.loading === true ? "Please wait" : "Sign In"
                      }
                      disabled={this.state.loading}
                      id={"loginBtn"}
                      className="btn btn-primary btn-block btn-flat"
                      onClick={this.handle_login}
                    />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <span>
                        {this.state.inValid
                          ? this.state.inValid
                          : this.state.error
                          ? "Please check connectivity"
                          : null}
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return {
    login: (
      firstName,
      accountType,
      accountName,
      accountId,
      sessionToken,
      sessionTime,
      userInfo
    ) => {
      dispatch({
        type: USER_LOGIN,
        firstName: firstName,
        accountType: accountType,
        accountName: accountName,
        accountId: accountId,
        sessionToken: sessionToken,
        sessionTime: sessionTime,
        userInfo: userInfo
      });
    }
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(login);
