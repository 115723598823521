import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ADD_INSTRUCTION } from "../store/actions/infoAction";
import Header from "./header";

class instruction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instruction: props.items.instruction,
      gateCode: props.items.gateCode,
      lockBoxCode:props.items.lockBoxCode
    };
  }

  onNext_handler = () => {
    this.props.addData(this.state.instruction,this.state.gateCode,this.state.lockBoxCode);
    this.props.history.push("/summary");
  };

  onCancel_handler = () => {
    this.props.clearInfo();
    this.props.logOut();
    this.props.history.push("/");
  };

  onInstructionChange_handler = e => {
    //this.setState({
    //  [e.target.name]: e.target.value
    //});
    this.setState({instruction: e.target.value})
  };

  onGateCodeChange_handler = e => {
    this.setState({gateCode: e.target.value})
  }

  onLockBoxCodeChange_handler = e => {
    this.setState({lockBoxCode: e.target.value})
  }

  onBack_handler = () => {
    if (this.props.items.appointment === "Not Set") {
      this.props.items.appointment = null;
      this.props.history.push("/measurment-list");
    }
    else {
      this.props.history.push("/calendar");
    }
  };

  onHelp_handler = () => {
    confirmAlert({
      title: "Special Measuring Instructions",
      message: (
        <div
        // onClick={() => onClose()}
        >
          <p>
            Type notes and/or special instructions for the measuring technician
            directly into the text box. Common Uses:
          </p>
          <ul style={{ listStyleType: "square" }}>
            <li>Property access information such as gate codes, etc.</li>
            <li>Parking Instructions</li>
            <li>Special contact information / requests</li>
          </ul>
        </div>
      ),
      buttons: [
        {
          label: "Okay"
        }
      ],
      closeOnEscape: true
    });
    // console.log(err);
  };

  render() {
    console.log("apmtTime = " , this.props.items.appointment);

    if (!this.props.auth.isLogin) {
      return <Redirect to="/" />;
    }
    if (this.props.auth.accountType === "Realtor" && !this.props.items.areas) {
      return <Redirect to="/area" />;
    }
    if (this.props.auth.accountType === "Retailer" && this.props.items.roomInformation.length === 0) {
      return <Redirect to="/products" />;
    }
    if (this.props.items.appointment === "Not Set") {
      console.log("Appointment Not Set");
      //return <Redirect to="/appointment-time" />;
    }
    else {
      if (!this.props.items.appointment) {
        return <Redirect to="/appointment-time" />;
      }
      if (!this.props.items.appointmentDate) {
        return <Redirect to="/calendar" />;
      }
    }

    return (
      <div>
        <Header />
        <section className="main-body">
          <div className="container">
            <div className="screen-eight">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="box box-primary">
                    <form className="">
                      <div className="box-header with-border text-center">
                        <h3 className="box-title">
                          Enter any Special Instructions for your Measurer
                        </h3>
                      </div>
                      <div className="box-body">
                        <textarea
                          name={"instruction"}
                          value={this.state.instruction}
                          onChange={this.onInstructionChange_handler}
                        ></textarea>


                      <label className="label-style" style={{marginTop:15}}>Gate Code:</label>
                          <input
                            tabIndex="1"
                            type="text"
                            maxlength="20"
                            className="form-control"
                            placeholder={
                              this.state.gateCode !== ""
                                ? this.state.gateCode
                                : "Gate Code"
                            }
                            name={"gateCode"}
                            value={this.state.gateCode}
                            onChange={this.onGateCodeChange_handler}
                          />


                      <label className="label-style" style={{marginTop:15}}>Lock Box Code:</label>
                          <input
                            tabIndex="2"
                            type="text"
                            maxlength="20"
                            className="form-control"
                            placeholder={
                              this.state.lockBoxCode !== ""
                                ? this.state.lockBoxCode
                                : "Lock Box Code"
                            }
                            name={"lockBoxCode"}
                            value={this.state.lockBoxCode}
                            onChange={this.onLockBoxCodeChange_handler}
                          />
                          </div>


                      <div className="box-footer with-border">
                        <div className="">
                          <ul className="custom-list">
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Back"
                                onClick={this.onBack_handler}
                                id="next-s10"
                              />
                            </li>
                            <div>
                              <li className="list-inline-item">
                                <input
                                  type="button"
                                  className="btn btn-primary"
                                  value="Help"
                                  onClick={this.onHelp_handler}
                                  id="next-s8"
                                />
                              </li>

                              <li className="list-inline-item">
                                <input
                                  type="button"
                                  className="btn btn-primary"
                                  value="Cancel"
                                  onClick={this.onCancel_handler}
                                  id="next-s1"
                                />
                              </li>
                            </div>
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Next"
                                onClick={this.onNext_handler}
                                id="next-s9"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => {
      dispatch({
        type: "USER_LOGOUT"
      });
    },
    clearInfo: () => {
      dispatch({
        type: "CLEAR_INFO"
      });
    },
    addData: (instructions,gateCode,lockBoxCode) => {
      dispatch({
        type: ADD_INSTRUCTION,
        data: {
          "instruction" : instructions,
          "gateCode": gateCode,
          "lockBoxCode" : lockBoxCode
        }
      });
    },
    setCookieState: data => {
      dispatch({
        type: "setFromCookie",
        data: data
      });
    }
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    items: state.items
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(instruction);
