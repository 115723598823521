import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { ADD_PRODUCT_TYPE } from "../store/actions/infoAction";
import Header from "./header";
import Footer from "./footer";

class productList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: null
    };
    // // console.log(this.props.cookies.get("userAuth"));
  }

  componentDidMount() {
    const cookieData = this.props.auth.sessionToken;
    axios
      .post(
        "https://www.floorsoft.com/FloorWizard/fwapi/measuremaker/productRequest",
        {
          sessionToken: cookieData,
          productList: null,
          messageList: null
        }
      )
      .then(res => {
        let data = res.data.productList;
        this.setState({
          productList: data
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  shouldComponentUpdate = (prev, next) => {
    if (this.props !== next.props) return true;

    return false;
  };

  onBack_handler = () => {
    this.props.history.push("/customer-information");
  };

  onSelect_handler = item => {
    this.props.addProduct(item);
    document.cookie = "selectedItem = " + JSON.stringify(item);
    this.props.history.push("/room-information");
  };

  onCancel_handler = () => {
    this.props.clearInfo();
    this.props.logOut();
    this.props.history.push("/");
    // this.props.history.push("/customer-information");
  };

  render() {
    if (!this.props.auth.isLogin) {
      return <Redirect to="/" />;
    }
    if (this.props.auth.accountType === "Realtor") {
      return <Redirect to="/area" />;
    }
    if (this.props.items.customerInfo === null) {
      return <Redirect to="/customer-information" />;
    }

    let productComponent = (
      <div style={{ width: "100%", textAlign: "center" }}>
        <h4>Please wait</h4>
      </div>
    );

    if (this.state.productList) {
      productComponent = this.state.productList.sort().map((item, i) => {
        return (
          <div className="col-sm-6 order-sm-1 order-1" key={i}>
            <label
              // tabIndex={i === 0 ? 1 : i}
              className="form-control text-center btn-lable next-s3"
              onClick={() => this.onSelect_handler(item)}
            >
              <strong>{item}</strong>
            </label>
          </div>
        );
      });
    }

    return (
      <div style={{ minHeight: "100vh" }}>
        <Header />
        <section
          className="main-body"
          style={{ minHeight: "calc(100vh - 48px - 61px)" }}
        >
          <div className="container">
            <div className="screen-two">
              <div className="box box-primary">
                <div className="box-header with-border text-center">
                  <h3 className="box-title">Select a Flooring Product Type</h3>
                </div>
                <form className="">
                  <div className="box-body">
                    <div className="row">{productComponent}</div>
                  </div>
                  <div className="box-footer with-border">
                    <div className="">
                      <ul className="custom-list">
                        <li className="list-inline-item">
                          <input
                            type="button"
                            className="btn btn-primary"
                            value="Back"
                            onClick={this.onBack_handler}
                            id="prev-s1"
                          />
                        </li>
                        <li
                          className="list-inline-item"
                          style={{ marginRight: "90px" }}
                        >
                          <input
                            type="button"
                            className="btn btn-primary"
                            value="Cancel"
                            onClick={this.onCancel_handler}
                            id="prev-s1"
                          />
                        </li>
                        <div></div>
                      </ul>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <div style={{ position: "absolute", width: "100%" }}> */}
        <Footer />
        {/* </div> */}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => {
      dispatch({
        type: "USER_LOGOUT"
      });
    },
    clearInfo: () => {
      dispatch({
        type: "CLEAR_INFO"
      });
    },
    addProduct: productName => {
      dispatch({
        type: ADD_PRODUCT_TYPE,
        data: productName
      });
    },
    login: (
      firstName,
      accountType,
      accountName,
      accountId,
      sessionToken,
      sessionTime
    ) => {
      dispatch({
        type: "USER_LOGIN",
        firstName: firstName,
        accountType: accountType,
        accountName: accountName,
        accountId: accountId,
        sessionToken: sessionToken,
        sessionTime: sessionTime
      });
    },
    setCookieState: data => {
      dispatch({
        type: "setFromCookie",
        data: data
      });
    }
  };
};
const mapStateToProps = state => {
  return {
    auth: state.auth,
    items: state.products
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(productList);
