import React, { Component } from "react";

class footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h6>
                powered by <span>Floorwizard</span>
              </h6>
            </div>
            <div className="col-6">
              <a
                href="https://www.floorsoft.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="company-link"
              >
                {" "}
                www.floorsoft.com
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default footer;
