import React, { Component } from "react";
import { connect } from "react-redux";

class header extends Component {
  render() {
    let accountName = this.props.auth.accountName;
    let projectName = null;
    if (this.props.items.customerInfo) {
      let returnpropertyName = '';
      if(this.props.items.customerInfo.propertyName === undefined){
        returnpropertyName = '';
      }
      else {
        returnpropertyName = this.props.items.customerInfo.propertyName;
      }
      projectName =
        returnpropertyName.trim() === ""
          ? this.props.items.customerInfo.firstName +
            this.props.items.customerInfo.lastName
          : this.props.items.customerInfo.propertyName;
    }
    return (
      <div>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <h3 id="show_af_sesc">{accountName}</h3>
              </div>
              <div className="col-sm-4 text-center">
                <h3 className="xs-mb0">{projectName}</h3>
              </div>
              <div className="col-sm-4"></div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    items: state.items
  };
};

export default connect(mapStateToProps)(header);
