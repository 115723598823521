import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ADD_DATE } from "../store/actions/infoAction";
import Header from "./header";

//css
import "./css/customCssCalendar.css";

class calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDay: props.items.appointmentDate
        ? this.props.items.appointmentDate
        : null,
      disabledDays: [],
      selectAnother: false
    };
  }

  componentDidMount() {
    this.updateDisabledDays(new Date());
    if (localStorage.getItem("projectId")) {
      this.setState({
        selectAnother: true
      });
    }
  }

  updateDisabledDays = date => {
    const newYear = new Date(date).getFullYear();
    const newMonth = new Date(date).getMonth();
    var timeOfDay = this.props.items.appointment;
    if (this.props.items.appointment === "AM or PM") {
      timeOfDay = "Both";
    }
    axios
      .post(
        "https://www.floorsoft.com/FloorWizard/fwapi/measuremaker/projectMeasureAppointmentAvailabilityRequest",
        {
          // sessionToken: "James-Home 2210195 2020-06-03 05:34:06 PM EST",
          sessionToken: this.props.auth.sessionToken,
          messageList: null,
          freeBlocksRequired: 8,
          pojoMonthAvailability: null,
          month: newMonth,
          year: newYear,
          timeOfDay: timeOfDay
        }
      )
      .then(res => {
        let dayAvailabilityList =
          res.data.pojoMonthAvailability.dayAvailabilityList;
        dayAvailabilityList = dayAvailabilityList.filter(
          day => day.available === false
        );
        let disabledDays = dayAvailabilityList.map(
          day => new Date(newYear, newMonth, day.dayOfMonth)
        );
        if (
          (this.props.items.appointment === "Morning" &&
            new Date().getHours() > 9) ||
          (this.props.items.appointment === "Afternoon" &&
            new Date().getHours() > 11)
        ) {
          disabledDays.push(new Date());
        }
        // console.log(disabledDays)
        this.setState({ disabledDays: disabledDays });
      })
      .catch(err => console.log(err));
  };

  handleDayClick = (day, { selected }) => {
    const disabledList = [...this.state.disabledDays];
    day.setHours(0, 0, 0, 0);
    const isDisabled = disabledList.filter(
      date =>
        new Date(date).getDate() === new Date(day).getDate() &&
        new Date(date).getMonth() === new Date(day).getMonth() &&
        new Date(date).getFullYear() === new Date(day).getFullYear()
    );

    if (
      isDisabled.length === 1 ||
      new Date(day.toDateString()) < new Date(new Date().toDateString())
    ) {
      this.setState({
        selectedDay: null
      });
      return;
    }
    this.setState({
      selectedDay: selected ? null : day
    });
  };

  onNext_handler = () => {
    if (this.state.selectedDay !== null) {
      this.props.addAppointmentDate(
        new Date(this.state.selectedDay).toDateString()
      );
      this.props.history.push("/instruction");
    }
  };

  onCancel_handler = () => {
    this.props.clearInfo();
    this.props.logOut();
    this.props.history.push("/");
    // this.props.history.push("/appointment-time");
  };

  onBack_handler = () => {
    this.props.history.push("/appointment-time");
  };

  onHelp_handler = () => {
    confirmAlert({
      title: "Appointment Date Availability",
      message:
        "The appointment calendar indicates the dates which are currently available based on the real-time schedules of all measuring technicians in your area.",
      buttons: [
        {
          label: "Okay"
        }
      ],
      closeOnEscape: true
    });
  };

  render() {
    if (!this.props.auth.isLogin) {
      return <Redirect to="/" />;
    }
    if (this.props.auth.accountType === "Realtor" && !this.props.items.areas)
      return <Redirect to="/area" />;

    if (
      this.props.auth.accountType === "Retailer" &&
      this.props.items.roomInformation.length === 0
    )
      return <Redirect to="/products" />;
    if (!this.props.items.appointment) {
      return <Redirect to="/appointment-time" />;
    }
    if (this.state.selectAnother) {
      confirmAlert({
        title: "Attention",
        message:
          "Sorry, appointment is not available for selected date. Please select another date.",
        buttons: [
          {
            label: "Okay",
            onClick: () => {
              //removing projectID if date is not available, so popup for date gets hidden
              // localStorage.removeItem("projectId");
              this.setState({
                selectAnother: false
              });
            }
          }
        ],
        closeOnEscape: true
      });
    }
    return (
      <div>
        <Header />
        <section className="main-body">
          <div className="container">
            <div className="screen-six">
              <div className="row">
                <div className="col-md-8 offset-md-2 col-sm-12">
                  <div className="box box-primary">
                    <form className="">
                      <div className="box-header with-border text-center">
                        <h3 className="box-title">
                          Select a Day for your Appointment
                        </h3>
                      </div>
                      {/* className="box-body" */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <DayPicker
                          selectedDays={this.state.selectedDay}
                          onDayClick={this.handleDayClick}
                          disabledDays={[
                            ...this.state.disabledDays,
                            { before: new Date() }
                          ]}
                          onMonthChange={data => this.updateDisabledDays(data)}
                          fromMonth={new Date()}
                        />
                      </div>
                      <div className="box-footer with-border">
                        <div className="">
                          <ul className="custom-list">
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Back"
                                onClick={this.onBack_handler}
                                id="prev-s5"
                              />
                            </li>
                            <div>
                              <li className="list-inline-item">
                                <input
                                  type="button"
                                  className="btn btn-primary"
                                  value="Help"
                                  onClick={this.onHelp_handler}
                                  id="prev-s5"
                                />
                              </li>

                              <li className="list-inline-item">
                                <input
                                  type="button"
                                  className="btn btn-primary"
                                  value="Cancel"
                                  onClick={this.onCancel_handler}
                                  id="prev-s5"
                                />
                              </li>
                            </div>
                            <li className="list-inline-item">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Next"
                                disabled={
                                  this.state.selectedDay === null
                                    ? "disabled"
                                    : ""
                                }
                                onClick={this.onNext_handler}
                                id="next-s7"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addAppointmentDate: data => {
      dispatch({
        type: ADD_DATE,
        data: data
      });
    },
    logOut: () => {
      dispatch({
        type: "USER_LOGOUT"
      });
    },
    clearInfo: () => {
      dispatch({
        type: "CLEAR_INFO"
      });
    },
    setCookieState: data => {
      dispatch({
        type: "setFromCookie",
        data: data
      });
    }
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    items: state.items
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(calendar);
