import { GET_PRODUCTS } from "../actions/productAction";

const initialState = {
  productList: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      state=action.products
      return state;
    default:
      return state;
  }
};
